// import React from "react"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import "../components/layout.css"
// import Explain from "../components/Explain"
// import {ExplainDataOne, ExplainDataTwo} from "../data/ExplainData"

// const About = () => (
//   <Layout>
//     <SEO title="About Us" />
//     <h1 className="about_us">About Us</h1>
//     <Explain {...ExplainDataOne} />
//     <Explain {...ExplainDataTwo} />
//   </Layout>
// )

// export default About

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Layout } from '../components/common/Layout'
import Head from '../components/common/Head'
import Explain from "../components/Explain"
import Scroll from "../components/Scroll"
import InfoSection from '../components/InfoSection'
import InfoSection2 from '../components/InfoSections/InfoSection2'
import InfoSection3 from '../components/InfoSections/InfoSection3'
import InfoSection4 from '../components/InfoSections/InfoSection4'


import {ExplainDataOne, ExplainDataTwo} from "../data/ExplainData"
import { aboutObjOne, aboutObjTwo, aboutObjThree } from "../components/InfoData/Data"


const About = () => (
	<Layout>
		<>
	  <Scroll showBelow={250} />
	  <Head title="AboutPageTitle" />
      <h1 className="about_us"><FormattedMessage id="About" /></h1>
      {/* <Explain {...ExplainDataOne} /> */}
      <InfoSection2 {...aboutObjOne}/>
	  <InfoSection3 {...aboutObjTwo}/>
      <InfoSection4 {...aboutObjThree}/>
		</>
	</Layout>
)

export default About
